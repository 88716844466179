@import '~antd/dist/antd.css';

/* layout */
.ant-layout {
    background: #fff;
}

.ant-layout-header {
    position: fixed;
    z-index: 3;
    width: 100%;
    line-height: 30px;
    padding: 15px 40px;
}

.ant-layout-header, .ant-layout-content {
    background: transparent;
}

.cover-layout-header {
    background: linear-gradient(to bottom left, #055878, #13769A);
}

.ant-layout-content {
    min-height: calc(100vh - 70px);
}

.ant-layout-footer {
    padding: 13px 50px;
    text-align: center;
}

.filings, .filings:hover {
    color: #158ad8;
    margin-left: 2px;
}

/* nav */
.logo, .logo-description, .ant-menu, .lang {
    color: #fff;
}

.logo {
    font-size: 26px;
    font-weight: bold;
    cursor: pointer;
}

.logo a, a:hover {
    color: #fff;
}

.logo-description {
    margin-left: 8px;
}

@media screen and (max-width: 934px) {
    .logo-description {
        display: none
    }
}

.ant-menu {
    width: 100%;
    background: transparent;
    font-size: 16px;
}

.ant-menu-sub {
    background-color: #000;
}

@media screen and (max-width: 426px) {
    .ant-menu {
        width: 100%;
        background: transparent;
        color: #000;
    }
}

.ant-menu-item-selected, .ant-menu-item-active {
    color: #fff !important;
}

.ant-menu-horizontal:hover::after {
    background: #000 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after {
    border-bottom: 1px solid #fff;
}

.ant-menu-horizontal {
    border-bottom: 0px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 10px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #fff;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #fff;
}

.ant-menu-horizontal {
    border-bottom: 0px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 10px;
}

.lang > .ant-btn-text {
    color: #fff;
}

.page-top-seize-seat {
    height: 64px;
}

.home-top {
    background: linear-gradient(to bottom left, #055878, #13769A);
    min-height: 70vh;
}

.home-bg-top-gene {
    position: absolute;
    top: 0;
    left: 0;
}

.home-bg-bottom-gene {
    position: absolute;
    bottom: 0;
    right: 0;
}

.home-bg-centor-door {
    height: 20vh;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.home-top-contain {
    text-align: center;
    margin-top: calc(5vh + 70px);
}

.home-welcome-title {
    font-size: 38px;
    font-weight: 999;
    color: #fff;
    padding: 50px 20px;
}

.search, .class-item {
    margin: 60px 0 0 0;
}

.search .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 60px;
}

.search .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector {
    border-radius: 6px 0 0 6px;
}

.search .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
    line-height: 60px;
}

.search .ant-input-lg {
    height: 60px;
}

.search .ant-input, .ant-input:focus, .ant-input:hover {
    border: none;
    box-shadow: none;
}

.search .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #d9d9d9;
}

.search .ant-input-group-addon:last-child {
    border-radius: 0 6px 6px 0;
}

.search .ant-input-group-addon {
    background-color: #fff;
}

.search .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 6px;
    margin: 0 7px;
    height: 46px;
    background: #cbe5f0;
    color: #3892b5;
    border: none;
}
.search .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
}

.example {
    margin: 30px 0 0 0;
    color: #fff;
    font-size: 16px;
}

.example a, .example:hover, .exampe:visited {
    color: #fff;
}

.example span {
    margin: 0 5px;
    cursor: pointer;
}

.class-item h1 {
    font-size: 38px;
    color: #fff;
    margin: 0;
}

.statistics-number {
    font-size: 16px;
    color: #fff;
}

.home-bottom {
    min-height: calc(30vh - 70px);
    text-align: center;
    padding: 30px 10%;
   
}
.home-search-result {
    position: absolute;
    width: 100%;
    height: 53vh;
    background-color: #fff;
    bottom: 0;
    left: 0;
}

.home-description, .page-description-contain {
    font-size: 16px;
}

.title-box {
    background: #f0f0f0;
    height: 14vh;
    background-size: contain;
}

.line-background {
    width: 100%;
    max-height: 14vh;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1;
    object-fit: contain;
}

.page-title, .page-description-title {
    z-index: 999;
    font-size: 40px;
    font-weight: 900;
}

.map-contain {
    padding: 6vh 0;
    min-height: calc(86vh - 140px);
}

.map, .page-description {
    height: 100%;
}

.page-description-title {
    font-size: 24px;
}

.page-description-title-sub {
    margin-left: 10px;
    font-style: italic;
    color: #c0c0c0;
    text-decoration: underline;
}

.browse-contain, .tool-center, .tool-bottom {
    padding: 0 8% 20px 8%;
}

.page-description-contain, .browse-contain {
    color: #000;
}

.browse-contain {
    padding-top: 5vh;
    font-size: 16px;
}

.grey_description {
    width: 100%;
    min-height: 50px;
    line-height: 50px;
    background: rgb(231, 235, 238);
    padding: 0 20px;
}

.browse-contain .ant-card-head, .ant-tag {
    border: none;
    white-space: break-spaces;
}

.browse-contain .ant-card-head-title {
    font-style: italic;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}

.browse-card {
    border-radius: 6px;
    font-weight: 500;
    transition: all .5s;
}

.browse-card:hover {
    box-shadow: 3px 3px 3px #ccc;
}

.ant-card-actions {
    border-radius: 0 0 6px 6px;
}

.browse-card .ant-card-body {
    min-height: 450px;
    padding: 24px 24px 0px 24px;
}

.browse-card .ant-card-actions > li > span a:not(.ant-btn), .ant-card-actions > li > span > .anticon {
    color: #fff;
}

.browse-contain .ant-card-actions, .browse-contain .ant-card-actions:hover, .browse-contain a:hover {
    background: #02709b;
    color: #fff !important;
}

.tool-center {
    text-align: center;
}

.tool-bottom {
    padding-top: 10vh;
}

.tool-center, .download-center {
    background: url('./images/topBackground.png') no-repeat;
    background-size: cover;
}

.dvpred-contain {
    padding: 6vh 5vh;
}

.dvpred-title {
    font-size: 38px;
}

.dvpred-description {
    padding: 0 20%;
    font-size: 16px;
}

.dvpred-contain .ant-input[disabled] {
    background: #fff;
}

.dvpred-contain .ant-input-search .ant-input-lg, .ant-input-search-large .ant-input-search-button {
    height: 60px;
    border: none;
}

.dvpred-table-head-color-odd, .dvpred-table-head-color-even {
    text-align: center !important;
    color: #fff !important;
}

.dvpred-table-head-color-odd {
    background: #3892b5 !important;
}

.dvpred-table-head-color-even {
    background: #02709b !important;
}

.download-center .ant-card-body {
    padding: 0 24px;
}

.download-contain {
    padding: 6vh 8%;
}

.download-contain .ant-card-head-title {
    text-align: left;
    font-size: 26px;
    padding: 24px 0 16px 0;
}

.download-contain .ant-card-body {
    font-size: 16px;
}

.download-item {
    overflow: hidden;
    margin-bottom: 20px;
}

.card-title {
    font-size: 20px;
    position: relative;
    z-index: 2;
}

.circle {
    width: 210px;
    height: 210px;
    border-radius: 105px;
    background-color: #cbe5f0;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -67px;
    margin-right: -55px;
    z-index: 0;
}

.download-item-icon {
    position: absolute;
    top: 3vh;
    right: 5vh;
    z-index: 1;
}

.download-item-date {
    z-index: 2;
    line-height: 20px;
    font-size: 14px;
    color: #666;
}

.download-item-description {
    position: relative;
    z-index: 2;
}

.download-item-bottom {
    position: absolute;
    bottom: 3.5vh;
    width: 100%;
    padding-right: 5vh;
}

.download-btn, .download-contain .ant-btn:hover, .download-contain .ant-btn:focus {
    background: #ffa11a;
    color: #fff;
    border: none;
    border-radius: 3px;
}

.download-contain .ant-table-thead > tr > th {
    background: #f6f6f6;
    height: 70px;
}

.contact-page {
    font-size: 16px;
    width: 100%;
    padding: 0 8%;
}

.subsection, .gene-list {
    margin-top: 50px;
}

.ant-carousel .slick-dots {
    bottom: -30px;
}

.ant-carousel .slick-dots li {
    background: #13769A;
    width: 24px;
    border-radius: 2px;
}

.subsection .ant-list-split .ant-list-item {
    border-bottom: none;
}

.variant-contain, .gene-contain, .disease-contain {
    padding: 60px 8% 0 8%;
    min-height: calc(100vh - 140px);
}

.variant-thrid-title {
    font-family: #112437;
    font-size: 16px;
    margin-right: 5px;
    font-weight: 500;
}

.gene-title-mark {
    width: 10px;
    height: 37px;
    background: #02709b;
    border: none;
    border-radius: 10px;
    margin-right: 10px;
    float: left;
}

.gene-title-contain {
    font-size: 26px;
    color: #02709b;
    font-style: italic;
}

.gene-tabs-contain {
    margin: 3vh 0 5vh 0;
}

.target, .target:hover {
    color: #02709b;
}

.gene-item-tabs {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    background: #f6f6f6;
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: bold;
}

.gene-item-tabs:hover, .gene-item-tabs-active {
    background: #3892b5;
    color: #fff;
}

.variant-filter {
	font-size: small;
}

.box-border {
	border: 1px solid #f6f6f6;
}

.box-border-radius {
    border-radius: 3px 3px 0 0;
}

.variant-filter .ant-input:hover, .variant-filter .ant-input:focus {
    border: 1px solid #158ad8;
    border-radius: 2px;
}

.variant-filter .ant-form-item-control {
	margin: 2px 0;
}

.variant-filter .ant-form-item {
	margin-bottom: 0px;
}

.variant-filter .ant-form-item-label {
	text-align: left;
	height: 40px;
	line-height: 40px;
    border: none;
	border-right: 1px solid #f6f6f6;
	margin: 0 12px;
	font-size: 18px;
	font-weight: bold;
}

.reset-btn, .query-btn {
    width: 80px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    padding-top: 0;
    font-weight: bold;
}

.query-btn, .query-btn:hover, .query-btn:focus, .query-btn:active {
    color: #fff;
    border: none;
    background: #ffa11a;
}

.ant-collapse-header-text {
    font-weight: bold;
    height: 24px;
    line-height: 24px;
}

.ant-carousel .slick-dots-top {
    top: -12px
}

.ant-carousel .slick-dots li {
    width: 24px;
}

.ant-carousel .slick-dots li.slick-active {
    width: 32px;
}

.ant-carousel .slick-dots li button {
    background: #3892b5;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #02709b;
}

.ant-switch-checked {
    background: #02709b;
}

.ant-list-item-meta-description {
    color: #000;
}

.ant-ribbon-wrapper {
    margin-bottom: 20px;
}

.ant-collapse-header {
    padding: 12px 0 !important;
}

.disease-contain .ant-badge {
    margin: 5px 0;
    border-radius: 5px;
    background-color: rgb(187, 216, 233);
    cursor: pointer;
}